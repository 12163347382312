html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:focus, a:hover {
  text-decoration: none;
}


body{
  color: $main-grey;
}

.no-padding {
  padding: 0 !important;
  margin: 0 !important;
}


.row-no-padding {
  [class*="col-"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}






.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}


hr {
  margin-top: 0px;
  border: 0;
  border-top: 6px solid $theme-primary;
  margin-bottom: 0;
}

.center-on-top {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  text-align: center;
}

.btn.active, .btn:active, .comment-form input.active[type=submit], .comment-form input[type=submit]:active, .search-form .active.search-submit, .search-form .search-submit:active {
  outline: 0;
  background-image: none;
  box-shadow: none
}



.nav-tabs-justified>li>a, .nav-tabs.nav-justified>li>a, .nav-tabs > li > a {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
 /* border: none!important;*/
}

.no-border, .no-border > li > a{
  border: none!important;
}


.nav-tabs>li>a {
  margin-right: 0px
  }


.top5 { margin-top:5px; }
.top7 { margin-top:7px; }
.top10 { margin-top:10px; }
.top15 { margin-top:15px; }
.top17 { margin-top:17px; }
.top20 { margin-top:20px; }
.top30 { margin-top:30px; }
.top50 { margin-top:50px; }
.top60 { margin-top:60px; }


.bottom5 { margin-bottom:5px; }
.bottom7 { margin-bottom:7px; }
.bottom10 { margin-bottom:10px; }
.bottom15 { margin-bottom:15px; }
.bottom17 { margin-bottom:17px; }
.bottom20 { margin-bottom:20px; }
.bottom30 { margin-bottom:30px; }
.bottom50 { margin-bottom:50px; }
.bottom60 { margin-bottom:60px; }


.h1, .h2, .h3, h1, h2, h3, h4 {
   margin: 0;
}

h1{
  font-size: 40px;
  line-height: 35px;
  font-family:'Bebas Neue';
  color: #ffffff;
  margin-top: 20px;
  @include MQ(M) {
    font-size: 65px;
    line-height: 60px;
    margin-top: 100px;

  }
}


h2{
  font-family:'Bebas Neue';
  font-size: 50px;
  line-height: 45px;
  color: $theme-primary;
  text-align: center;

}

h3{
  font-family:'Bebas Neue';
  font-size: 35px;
  line-height: 30px;
  color: $white;
  text-align: center;

  @include MQ(M) {
    font-size: 50px;
    line-height: 45px;
  }
}

h4{
  font-family:'Bebas Neue';
  font-size: 20px;
  color: $theme-primary;
  text-transform: uppercase;
}




/* reset aos glows*/

.form-control:focus, a:focus {
  box-shadow: none}

:focus {
  outline-color: transparent;
  outline-style: none;
}



.btn:focus,.btn:active {
  outline: none !important;
}


.black-header{
  background-color: #000;
  padding-left: 0;
  padding-right: 0;
}

