#footer{
  hr{
    margin-top: 60px;
    border-top: 1px solid #D7D7D7;
    margin-bottom: 50px;

  }
}

a.link-footer{
  font-size: 12px;
  color: $light-grey;
  TEXT-transform: uppercase;
}


.logo-footer{

  display: block;
  margin-left: auto;
  margin-right: auto;
  @include MQ(M ) {
    margin-left: 0;
    margin-right: auto;
  }

}


.follow-us{
  font-size: 12px;
  display: inline;
}

#copy{
  font-size: 10px;
  padding: 0;
  border-bottom: 2px solid $theme-primary;
  background-color: #000;
  position: relative;
  .copy-txt{
    display: inline-block;
    padding-bottom: 9px;
    padding-top: 7px;
  }
}

.by-dreamlab{
  padding-bottom: 9px;
  padding-top: 7px;
}


.social{
  color:$light-grey;
  font-size: 1.5em;
  margin-left: 5px;
}




