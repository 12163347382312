.history-banner{
  margin-bottom: 20px;
  position: relative;
  h2{
    color:$white;
    width: 300px;
    line-height: 1px;
  }
}

.sobre-nos, .about{
  h3{
    color: $theme-primary;
  }

}



.owl-carousel  {
  width: calc(100% - 60px);
  margin: 0 auto;
}



.date{
  font-family: bebas;
  font-size: 17px;
  border-bottom: 1px solid $main-grey;
  padding-bottom: 6px;
  margin-bottom: 6px;

}


.dot {
  margin-top: -18px;
  margin-left: 11px;
}

.evol-text {
  padding: 14px;
  line-height: 15px;
}




.owl-wrapper {
  position: relative;

}

.customNavigation {
  top: 15px;
  position: relative;
}

a.btn.prev {
  position: absolute;
  left: 0px;
  color: $theme-primary;
  padding: 0;
}


a.btn.next {
  position: absolute;
  right: 0;
  color: $theme-primary;
  padding: 0;
}


.angle{
  top: 15px;
}



.owl-theme .owl-controls{
  margin:0 0 20px 0;

}

.nav-tabs>li>a {

  text-transform: uppercase;
  color: $main-grey;
  font-size: 20px;
  font-family:'Bebas Neue';
  border-top: none!important;
}

.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
  color: $white;
  background-color: $theme-primary;
}


.awards{
  background-color: #F3F3F3;

  .customNavigation {
    top: 5  0px;
    position: relative;
  }
}


#owl-premios{
  .owl-controls{
    display: none!important;
  }
}


#owl-certifications{
  .owl-controls{
    display: none!important;

  }

  text-align: center;
  p{
    text-align: left;

  }

}


p.title {
  font-size: 16px;
  color: $theme-primary;
  margin-top: 20px;
}


