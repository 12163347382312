.mapa{
  height: 350px;
}




.get-info{
  font-size: 22px;
  font-family:'Bebas Neue';
  color:$theme-primary;

}

.address {
  background-color: $theme-primary;
  color: white;
  padding: 35px;
  margin: 40px 0;
  @include MQ(M) {
    margin-top: -80px;
  }

}

.contact-title {
  text-transform: uppercase;
  font-size: 22px;
  font-family:'Bebas Neue';
  margin-bottom: 30px;
}

.contact-subtitle {
  font-size: 16px;
  margin-top: 30px;
}

.link a{
  color:$white
}


.input{
  background: #F3F3F3;
  border: none;
  width: 100%;
  padding: 5px;
}

.message{
  height: 138px;
}

.contact-btn{
  background-color: $theme-primary;
  border: none;
  margin-top: 10px;
  color: white;
  padding: 5px 22px;
}


.recrutamento{
  td, th {
    padding: 5px;
  }


}

.green{
  color: $theme-primary;
}

.outra{
  width: 100px;
}