.single-produto{
  background-color: transparent!important;
 // background-color: black!important;
  overflow-y: hidden;
  overflow-x: hidden;

  h2{
    font-size: 35px;
    line-height: 31px;
    color: white;
    text-align: left;
    padding:1px 0 10px 0;
    @include MQ(M) {
      font-size: 50px;
      line-height: 45px;
    }
  }


  .thumb{
    margin: 8px 8px 0 0;
    display: inline;
  }

  .more-info {
    background: #E8E8E8;
    padding: 25px;
    margin-top: 10px;

    @include MQ(M) {
      margin-top: -50px;
      height: 168px;
      padding: 50px;

    }
  }


}




.fancybox-opened .fancybox-skin {
  -webkit-box-shadow: 0 0 0;
  -moz-box-shadow: 0 0 0;
  box-shadow: 0 0 0;
}
.fancybox-skin {
  background: transparent;
}



.overlay-close{
  position: absolute;
  top: 5px;
  right: 10px;
  padding-bottom: 10px;
  @include MQ(M) {
    top: 15px;

  }
}