#langs-topbar {
  width: 100%;
  background: black;
  height: 32px;
  padding: 0;
  .list {
    right: 0;
    padding: 0px;
    li {
      text-transform: uppercase;
      width: 30px;
      height: 30px;
      display: inline-block;

      &.current-lang {
        > a,
        > a:focus {
          color: $theme-primary;
          background-color: transparent;
          &:hover {
            background-color: transparent;
          }
        }
      }
      a {
        color: #fff;
        display: block;
        padding: 5px;

        &:hover {
          color: $theme-primary;
        }
        &:focus {
          color: $theme-primary;
        }


      }
    }
  }
  .search-header{
    padding-top: 5px;
  }
}




#langs-topbar > .container {
  position: relative;
}

ol, ul {
  list-style: none;
}




.brand {
  height: 100px;
  img {
    display: block;
    margin-left: 0;
    margin-right: auto;
    @include MQ(M) {
      margin-left: auto;
      margin-right: auto;
    }

  }
}
.banner{
  background:  no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 350px;
  @include MQ(M ) {
    height: 645px;
  }

}


.header-sub-title{
  visibility: hidden;
  color: $white;
  border-top: 1px dashed;
  padding-top: 10px;

  @include MQ(M ) {
    visibility: visible;
  }
}





.cd-container { /* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
  width: 90%;
  max-width: $L; // breakpoints inside partials > _layout.scss
  margin: 0 auto;

  &::after { /* clearfix */
    content: '';
    display: table;
    clear: both;
  }
}

nav#cd-main-nav {
  margin-top: 25px;
}

/* --------------------------------

Main components

-------------------------------- */



#cd-main-nav {
  ul {
    background-color: $black;
    padding-left: 0;
    /* mobile first */
    text-align: center;
    position: absolute;
    width: 400px;
    right: 20px;
    top: 70px;
    @include border-radius;
    visibility: hidden;
    /* remove overflow:hidden if you want to create a drop-down menu - but then remember to fix/eliminate the list items animation */
    overflow: hidden;

    z-index: 1;

    /* Force Hardware Acceleration in WebKit */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    @include transform(scale(0));
    @include transform-origin(100% 0%);
    -webkit-transition: -webkit-transform 0.3s, visibility 0s 0.3s;
    -moz-transition: -moz-transform 0.3s, visibility 0s 0.3s;
    transition: transform 0.3s, visibility 0s 0.3s;

    li {
      /* Force Hardware Acceleration in WebKit */
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      margin: 5px 5.5em;
    }

    &.is-visible {
      visibility: visible;
      @include transform(scale(1));
      -webkit-transition: -webkit-transform 0.3s, visibility 0s 0s;
      -moz-transition: -moz-transform 0.3s, visibility 0s 0s;
      transition: transform 0.3s, visibility 0s 0s;

      li:nth-child(1) { /* list items animation */
        @include animation(cd-slide-in .2s);
      }

      li:nth-child(2) {
        @include animation(cd-slide-in .3s);
      }

      li:nth-child(3) {
        @include animation(cd-slide-in .4s);
      }

      li:nth-child(4) {
        @include animation(cd-slide-in .5s);
      }

      li:nth-child(5) {
        @include animation(cd-slide-in .6s);
      }
      li:nth-child(6) {
        @include animation(cd-slide-in .7s);
      }
    }
  }

  li a {
    display: block;

  }

  li:last-child a {
    border-bottom: none;
  }

  @include MQ(M) {
    ul {
      background : -moz-linear-gradient(36.37% -403.19% -60deg,rgba(26, 26, 26, 1) 0%,rgba(101, 101, 101, 1) 47.31%,rgba(26, 26, 26, 1) 98.92%);
      background : -webkit-linear-gradient(-60deg, rgba(26, 26, 26, 1) 0%, rgba(101, 101, 101, 1) 47.31%, rgba(26, 26, 26, 1) 98.92%);
      background : -webkit-gradient(linear,36.37% -403.19% ,63.63% 503.19% ,color-stop(0,rgba(26, 26, 26, 1) ),color-stop(0.4731,rgba(101, 101, 101, 1) ),color-stop(0.9892,rgba(26, 26, 26, 1) ));
      background : -o-linear-gradient(-60deg, rgba(26, 26, 26, 1) 0%, rgba(101, 101, 101, 1) 47.31%, rgba(26, 26, 26, 1) 98.92%);
      background : -ms-linear-gradient(-60deg, rgba(26, 26, 26, 1) 0%, rgba(101, 101, 101, 1) 47.31%, rgba(26, 26, 26, 1) 98.92%);
      -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#1A1A1A', endColorstr='#1A1A1A' ,GradientType=0)";
      background : linear-gradient(150deg, rgba(26, 26, 26, 1) 0%, rgba(101, 101, 101, 1) 47.31%, rgba(26, 26, 26, 1) 98.92%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1A1A1A',endColorstr='#1A1A1A' , GradientType=1);

      position: static;
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      padding: 0;
      bottom: auto;
      visibility: visible;
      box-shadow: none;
      @include transform(scale(1));
      @include transition(all 0s);
    }

    li {
      padding: 0 0px;
      margin: 0!important;
    }

    li a {
      padding-top: 15px;
      padding-bottom: 15px;


      &:hover {
        text-decoration: none;
      }
    }

    &.is-fixed ul {
      /* when the user scrolls down, the navigation moves to the bottom right in Fixed position - as on touch devices */
      position: fixed;
      width: 90%;
      max-width: 400px;
      bottom: 20px;
      top: auto;

      visibility: hidden;
      @include transform(scale(0));

      li {
        display: block;
      }

      li a {
        padding: 1.6em;
        margin-left: 0;
      }
    }

    ul.has-transitions {
      /* this class is used to bring back transitions when the navigation moves at the bottom right */
      -webkit-transition: -webkit-transform 0.3s, visibility 0s 0.3s;
      -moz-transition: -moz-transform 0.3s, visibility 0s 0.3s;
      transition: transform 0.3s, visibility 0s 0.3s;
    }

    ul.is-visible {
      /* this class is used to bring back the navigation animation - as on touch devices */
      visibility: visible;
      @include transform(scale(1));

      -webkit-transition: -webkit-transform 0.3s, visibility 0s 0s;
      -moz-transition: -moz-transform 0.3s, visibility 0s 0s;
      transition: transform 0.3s, visibility 0s 0s;
    }

    ul.is-hidden {
      /* this class is used to animate the scale down the navigation when the user scrolls down with the navigation still open */
      @include transform(scale(0));
      -webkit-transition: -webkit-transform 0.3s;
      -moz-transition: -moz-transform 0.3s;
      transition: transform 0.3s;
    }
  }
}


.navbar-nav * {
  box-sizing: border-box;
}
.navbar-nav li {
  display: inline-block;
  list-style: outside none none;
  margin: 0 1.5em;
  padding: 0;
}


.navbar-nav li.active a{
  color: $white;
}


.navbar-nav a {
  padding: 0.5em 0;
  color: rgba(255, 255, 255, 0.5);
  position: relative;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  display:block;
  text-align:center;
  text-decoration:none;

}
.navbar-nav a:before,
.navbar-nav a:after {
  position: absolute;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.navbar-nav a:before {
  bottom: 0;
  display: block;
  height: 6px;
  width: 0%;
  content: "";
  background-color: $theme-primary;
}
.navbar-nav a:after {
  left: 0;
  top: 0;
  padding: 0.5em 0;
  position: absolute;
  content: attr(data-hover);
  color: #ffffff;
  white-space: nowrap;
  max-width: 0%;
  overflow: hidden;
}
.navbar-nav a:hover:before,
.navbar-nav .active a:before {
  opacity: 1;
  width: 100%;
}
.navbar-nav a:hover:after,
.navbar-nav .active a:after {
  max-width: 100%;
  color: rgba(255, 255, 255, 0.5);
}




.cd-nav-trigger {
  background : -moz-linear-gradient(23.24% -12.29% -60deg,rgba(26, 26, 26, 1) 0%,rgba(101, 101, 101, 1) 47.31%,rgba(26, 26, 26, 1) 98.92%);
  background : -webkit-linear-gradient(-60deg, rgba(26, 26, 26, 1) 0%, rgba(101, 101, 101, 1) 47.31%, rgba(26, 26, 26, 1) 98.92%);
  background : -webkit-gradient(linear,23.24% -12.29% ,76.76% 112.29% ,color-stop(0,rgba(26, 26, 26, 1) ),color-stop(0.4731,rgba(101, 101, 101, 1) ),color-stop(0.9892,rgba(26, 26, 26, 1) ));
  background : -o-linear-gradient(-60deg, rgba(26, 26, 26, 1) 0%, rgba(101, 101, 101, 1) 47.31%, rgba(26, 26, 26, 1) 98.92%);
  background : -ms-linear-gradient(-60deg, rgba(26, 26, 26, 1) 0%, rgba(101, 101, 101, 1) 47.31%, rgba(26, 26, 26, 1) 98.92%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#1A1A1A', endColorstr='#1A1A1A' ,GradientType=0)";
  background : linear-gradient(150deg, rgba(26, 26, 26, 1) 0%, rgba(101, 101, 101, 1) 47.31%, rgba(26, 26, 26, 1) 98.92%);
  border-radius : 3px;
  -moz-border-radius : 3px;
  -webkit-border-radius : 3px;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1A1A1A',endColorstr='#1A1A1A' , GradientType=1);

  position: absolute;
  top: 70px;
  right: 20px;
  width: 44px;
  height: 44px;
  @include border-radius;
  /* image replacement */
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;

  z-index: 2;

  span {
    /* the span element is used to create the menu icon */
    position: absolute;
    display: block;
    width: 20px;
    height: 2px;
    background: $white;
    top: 50%;
    margin-top: -1px;
    left: 50%;
    margin-left: -10px;
    -webkit-transition: background 0.3s;
    -moz-transition: background 0.3s;
    transition: background 0.3s;

    &::before, &::after {
      content: '';
      position: absolute;
      left: 0;
      background: inherit;
      width: 100%;
      height: 100%;

      /* Force Hardware Acceleration in WebKit */
      @include transform(translateZ(0));
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;

      -webkit-transition: -webkit-transform 0.3s, background 0s;
      -moz-transition: -moz-transform 0.3s, background 0s;
      transition: transform 0.3s, background 0s;
    }

    &::before {
      top: -6px;
      @include transform(rotate(0));
    }

    &::after {
      bottom: -6px;
      @include transform(rotate(0));
    }
  }

  &.menu-is-open {
    background: $theme-primary;
  }

  &.menu-is-open span {
    background: rgba($white, 0);

    &::before, &::after {
      background: rgba($white, 1);
    }

    &::before {
      top: 0;
      @include transform(rotate(135deg));
    }

    &::after {
      bottom: 0;
      @include transform(rotate(225deg));
    }
  }

  @include MQ(M ) {
    /* the the menu triger is hidden by default on desktop devices */
    visibility: hidden;
    @include transform(scale(0));
    -webkit-transition: -webkit-transform 0.3s, visibility 0s 0.3s;
    -moz-transition: -moz-transform 0.3s, visibility 0s 0.3s;
    transition: transform 0.3s, visibility 0s 0.3s;

    .is-fixed & {
      visibility: visible;
      @include transition(all 0s);
      @include transform(scale(1));
      @include animation(cd-bounce-in .3s linear);
    }
  }
}



@include keyframes(cd-slide-in) {
  0% {
    @include transform(translateX(100px));
  }

  100% {
    @include transform(translateY(0));
  }
}

@include keyframes(cd-bounce-in) {
  0% {
    @include transform(scale(0));
  }

  60% {
    @include transform(scale(1.2));
  }

  100% {
    @include transform(scale(1));
  }
}


