// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}



//search box
input#s {
  border: none;
  border-bottom: 1px solid  $light-grey;
  background: transparent;
  font-size: 12px;
  width: 115px;
}

button#searchsubmit {
  background: none;
  border: none;

}