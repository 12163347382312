// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;



// Colors
$theme-primary:         #9DC438;
$white:                 rgba(255, 255, 255, 1);
$main-grey:             rgba(145, 145, 145, 1);

$light-grey:            rgba(159, 159, 159, 1); // 9F9F9F
$black :                #000; // black




@font-face {
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: normal;
  src: local('Bebas Neue'), url('../fonts/BebasNeue.woff') format('woff');
}

@font-face {
  font-family: 'Bebas Neue Book';
  font-style: normal;
  font-weight: normal;
  src: local('Bebas Neue Book'), url('../fonts/BebasNeue Book.woff') format('woff');
}