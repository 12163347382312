.areas-de-negocio{

  .intro-areas{
    margin-bottom: 65px;
  }


  h4{
    margin-top: 25px;
  }

  .areas-txt{
    padding-top: 10px;
  }

  .area-negocio-item {
    text-align: center;
    margin-top: 20px;
    height: 200px;
  }


}

