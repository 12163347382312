/* HOME PAGE*/


.home-highlights {
  background: rgba(0, 0, 0, 0.8);
  padding: 30px;
  font-size: 20px;
  position: absolute;
  top: 126px;
  @include MQ(L) {
    top: 168px;
  }


  .bordered{
    color: rgba(255, 255, 255, 0.3);;
    border-top: 1px dashed;
  }

  .figures {
    color: $theme-primary;
    font-family:'Bebas Neue';
    padding-right: 40px;
    font-weight: 400;

  }


  .title {
    color: $white;
    padding-top: 5px;
    font-family:'Bebas Neue';
    font-weight: 400;

  }


  .text{
    font-size: 10px;
    font-family: arial, sans-serif;
    padding-bottom: 5px;
  }

}


.home-highlights.EN{


  @include MQ(L) {
    top: 183px;

  }

}




.certifications {
  background : -moz-linear-gradient(34.96% -172.25% -60deg,rgba(51, 51, 51, 1) 0%,rgba(128, 128, 128, 1) 47.31%,rgba(51, 51, 51, 1) 98.92%);
  background : -webkit-linear-gradient(-60deg, rgba(51, 51, 51, 1) 0%, rgba(128, 128, 128, 1) 47.31%, rgba(51, 51, 51, 1) 98.92%);
  background : -webkit-gradient(linear,34.96% -172.25% ,65.04% 272.25% ,color-stop(0,rgba(51, 51, 51, 1) ),color-stop(0.4731,rgba(128, 128, 128, 1) ),color-stop(0.9892,rgba(51, 51, 51, 1) ));
  background : -o-linear-gradient(-60deg, rgba(51, 51, 51, 1) 0%, rgba(128, 128, 128, 1) 47.31%, rgba(51, 51, 51, 1) 98.92%);
  background : -ms-linear-gradient(-60deg, rgba(51, 51, 51, 1) 0%, rgba(128, 128, 128, 1) 47.31%, rgba(51, 51, 51, 1) 98.92%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#333333', endColorstr='#333333' ,GradientType=0)";
  background : linear-gradient(150deg, rgba(51, 51, 51, 1) 0%, rgba(128, 128, 128, 1) 47.31%, rgba(51, 51, 51, 1) 98.92%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#333333',endColorstr='#333333' , GradientType=1);
  border-bottom: 6px solid $theme-primary;

  .owl-pagination {
    display: none;
  }
}


.customNavigation{
  margin-top: 30px;
}


#owl-certifications-home {
  text-align: center;
}

.owl-item{
  margin-bottom: 30px;
}

.owl-controls.clickable {
  display: none;
}

.owl-arrow{
  margin-top: 50px;
}



img.responsive {
  padding-right: 1px;
  width: 100%;
}

.sub-title{
  font-size: 20px;
  color: $theme-primary;
  font-family: arial;
}


.center-on-top-home {
  position: absolute;
  top: 30%;
  -webkit-transform: translateY(-30%);
  -ms-transform: translateY(-30%);
  transform: translateY(-30%);
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  text-align: center;
}

.separator {
  background-color: #F3F3F2;
  margin: 60px 0 60px 0;
  text-align: center;
  font-family:'Bebas Neue';
  color: $theme-primary;
}

.doubt{
  padding-top: 10px;
  font-size: 40px;
  line-height: 35px;

  @include MQ(M) {
    font-size: 36px;
    line-height: 36px;
  }
}

.contact{
  font-size: 35px;
  font-family:'Bebas Neue Book';
  line-height: 30px;
  padding-bottom: 10px;
  a{
    color: $theme-primary;
  }
}