.product-description{
  padding: 35px 50px;
  h4{
    margin-bottom: 10px;
  }
}

.know-more{
  font-family:'Bebas Neue';
  color: $theme-primary;
  &:hover {
    color: $theme-primary;
    .white{
      color: white;
    }
  }
}

.light-grey{
  background-color: #F3F3F3;
}



.white{
  color: transparent;
}

