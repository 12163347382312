.intro-areas{
  margin-bottom: 65px;
}



.projects-header{
  background-color: #000;
  padding-left: 0;
  padding-right: 0;
    .nav-tabs>li{
    width: 100%;
      @include MQ(M) {
        width: 50%;
      }
    text-align: center;
    >a{
      margin-right: 0;
    }

  }
}


.projetos{
  h3 {
    font-size: 20px;
    line-height: 20px;

    @include MQ(M) {
      font-size: 50px;
      line-height: 45px;
    }
  }

}
