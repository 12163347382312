.row-no-padding {
  margin-left: 0;
  margin-right: 0;
  [class*="col-"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}



// rem fallback - credits: http://zerosixthree.se/

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

// border radius

@mixin border-radius($radius:.25em) {
  border-radius: $radius;
}

// breakpoints

$S:     320px;
$M:     768px;
$L:     991px;
//$L:     1170px;

// media queries

@mixin MQ($canvas) {
  @if $canvas == S {
    @media only screen and (min-width: $S) { @content; }
  }
  @else if $canvas == M {
    @media only screen and (min-width: $M) { @content; }
  }
  @else if $canvas == L {
    @media only screen and (min-width: $L) { @content; }
  }
}

// super light grid - it works with the .cd-container class inside style.scss

@mixin column($percentage, $float-direction:left) {
  width: 100% * $percentage;
  float: $float-direction;
}

